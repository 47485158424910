import React, { ChangeEvent, useState } from "react";

import { FormInput, PasswordInput, SelectInput } from "src/components/inputs";

import { Button } from "src/components/Button";
import { Formik, FormikValues, useFormik } from "formik";
import * as Yup from "yup";

import { useAddNewHQMutation } from "src/api/manageHQApiSlice";
import {
  convert2base64,
  generatePassword,
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import { states } from "src/helpers/data";
import { Upload } from "./Upload";
import { ShowVideoAndImage } from "./RenderImagePreview";

// YUP VALIDATION FOR ADD BRANCH TYPE
const AddbranchValidation = [
  Yup.object({
    accountType: Yup.string().label("Password").notRequired(),
    stationHQ: Yup.object({
      name: Yup.string().label("HQ name").required(),
      email: Yup.string().label("HQ email").email().required(),
      phoneNumber: Yup.string()
        .label("phone number")
        .length(11, "invalid")
        .required(),
    }),
  }),
  Yup.object({
    stationHQ: Yup.object({
      hqAddress: Yup.string().label("HQ address").required(),
      state: Yup.string().label("State").required(),
      logo: Yup.string().label("Logo").required(),
    }),
  }),
  Yup.object({
    firstName: Yup.string().label("First name").required(),
    lastName: Yup.string().label("Last name").required(),
    phoneNumber: Yup.string()
      .label("phone number")
      .length(11, "invalid")
      .required(),
    email: Yup.string().label("Email").email().required(),
    // password: Yup.string()
    // 	.min(6, "The password is too short")
    // 	.matches(
    // 		/^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#$%^&*])(?=.{8,})/,
    // 		"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // 	)
    // 	.required("Password is required!"),
    // gender: Yup.string<"male" | "female">().nullable().defined(),
  }),
  Yup.object({
    // password: Yup.string()
    // 	.min(6, "The password is too short")
    // 	.matches(
    // 		/^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#$%^&*])(?=.{8,})/,
    // 		"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    // 	)
    // 	.required("Password is required!"),
    // gender: Yup.string<"male" | "female">().nullable().defined(),
    gender: Yup.string().label("Gender").required(),
  }),
];

const AddNewHQ = (props: { close: () => void }) => {
  const [step, setStep] = useState<number>(0);
  const [AddNewHq, addNewResult] = useAddNewHQMutation();

  async function addNewHQ(values: FormikValues) {
    try {
      const response = await AddNewHq(values).unwrap();
      if (response) {
        props.close();
      }
      SuccessNotification("HQ Created");
      SuccessNotification(response?.message);
    } catch (error: any) {
      props.close();
      handleNotification(error);
    }
  }

  const Formik = useFormik<{
    stationHQ: {
      name: string;
      email: string;
      phoneNumber: string;
      hqAddress: string;
      state: string;
      logo: string;
    };
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    gender: string;
    accountType: string;
  }>({
    initialValues: {
      stationHQ: {
        name: "",
        email: "",
        phoneNumber: "",
        hqAddress: "",
        state: "",
        logo: "",
      },
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      gender: "",
      accountType: "stationHq",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: AddbranchValidation[step],
    onSubmit: (values) => {
      if (step === 3) {
        addNewHQ(values);
      } else {
        setStep((prev) => prev + 1);
      }
    },
  });

  const styles =
    "h-[38px] py-6 rounded-[38px] w-full border border-gray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 px-4 text-[14px] bg-[#D9D9D9]";
  const labelStyles =
    "block mb-[6px] text-black text-start font-normal text-[14px] text-black ml-5 my-6";

  const FormData = [
    {
      id: "stationHQ.name",
      name: "HQ name",
      type: "text",
      // styles: `${styles} ${
      // 	Formik.errors?.stationHQ?.name && Formik.touched?.stationHQ?.name
      // 		? "border-red-500"
      // 		: "border-gray-300"
      // }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values?.stationHQ?.name,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors?.stationHQ?.name,
      touched: Formik.touched?.stationHQ?.name,
    },
    {
      id: "stationHQ.email",
      name: "HQ email",
      type: "email",
      // styles: `${styles} ${
      // 	Formik.errors?.stationHQ?.email && Formik.touched.email
      // 		? "border-red-500"
      // 		: "border-gray-300"
      // }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values?.stationHQ?.email,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.stationHQ?.email,
      touched: Formik.touched.stationHQ?.email,
    },
    {
      id: "stationHQ.phoneNumber",
      name: "HQ phone number",
      type: "text",
      // styles: `${styles} ${
      // 	Formik.errors?.stationHQ?.phoneNumber &&
      // 	Formik.touched.stationHQ?.phoneNumber
      // 		? "border-red-500"
      // 		: "border-gray-300"
      // }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.stationHQ?.phoneNumber,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.stationHQ?.phoneNumber,
      touched: Formik.touched.stationHQ?.phoneNumber,
    },
    {
      id: "stationHQ.hqAddress",
      name: "HQ address",
      type: "text",
      // styles: `${styles} ${
      // 	Formik.errors?.stationHQ?.hqAddress &&
      // 	Formik.touched?.stationHQ?.hqAddress
      // 		? "border-red-500"
      // 		: "border-gray-300"
      // }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.stationHQ?.hqAddress,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.stationHQ?.hqAddress,
      touched: Formik.touched.stationHQ?.hqAddress,
    },
    {
      id: "firstName",
      name: "Manager's firstname",
      type: "text",
      styles: `${styles} ${
        Formik.errors.firstName && Formik.touched.firstName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.firstName,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.firstName,
      touched: Formik.touched.firstName,
    },
    {
      id: "lastName",
      name: "Manager's lastname",
      type: "text",
      styles: `${styles} ${
        Formik.errors.lastName && Formik.touched.lastName
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.lastName,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.lastName,
      touched: Formik.touched.lastName,
    },
    {
      id: "email",
      name: "Manager's email",
      type: "email",
      styles: `${styles} ${
        Formik.errors.email && Formik.touched.email
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.email,
      onBlur: Formik.handleBlur,

      // disabled: loginResult.isLoading,
      error: Formik.errors.email,
      touched: Formik.touched.email,
    },
    {
      id: "phoneNumber",
      name: "Manager's phone number",
      type: "text",
      styles: `${styles} ${
        Formik.errors.phoneNumber && Formik.touched.phoneNumber
          ? "border-red-500"
          : "border-gray-300"
      }`,
      labelStyles: labelStyles,
      onChange: Formik.handleChange,
      value: Formik.values.phoneNumber,
      onBlur: Formik.handleBlur,
      disabled: addNewResult?.isLoading,
      error: Formik.errors.phoneNumber,
      touched: Formik.touched.phoneNumber,
    },
  ];

  // console.log(Formik.errors);
  // HANDLE IMAGE UPLOAD TO BASE 64
  async function uploadAvatar(e: { [index: string]: string | any }) {
    Formik.setFieldValue(
      "stationHQ.logo",
      await convert2base64(e.target.files[0])
    );
  }
  console.log(Formik.errors);
  return (
    <form
      onSubmit={Formik.handleSubmit}
      className="w-full flex flex-col justify-center items-center px-4 pb-4"
    >
      {step === 0 ? (
        <div className="grid grid-cols-1 w-full gap-x-2 content-center">
          {FormData.slice(0, 3).map((dt, i) => (
            <>
              <FormInput
                id={dt.id}
                name={dt.name}
                type={dt.type}
                styles={dt.styles}
                labelStyles={dt.labelStyles}
                onChange={dt.onChange}
                value={dt.value}
                onBlur={dt.onBlur}
                disabled={dt.disabled}
                error={dt.error as any}
                touched={dt.touched as any}
              />
            </>
          ))}
        </div>
      ) : null}
      {step === 1 ? (
        <>
          {FormData.slice(3, 4).map((dt, i) => (
            <>
              <FormInput
                id={dt.id}
                name={dt.name}
                type={dt.type}
                styles={dt.styles}
                labelStyles={dt.labelStyles}
                onChange={dt.onChange}
                value={dt.value}
                onBlur={dt.onBlur}
                disabled={dt.disabled}
                error={dt.error as any}
                touched={dt.touched as any}
              />
            </>
          ))}

          <SelectInput
            id="state"
            data={states}
            labelStyles={labelStyles}
            name="Select state"
            onChange={(e) =>
              Formik.setFieldValue(
                "stationHQ.state",
                e.target.value?.toLowerCase()
              )
            }
            value={Formik.values.stationHQ.state}
          />

          <div className="flex-col w-full items-center justify-between overflow-x-auto ">
            <ShowVideoAndImage
              media={[Formik.values?.stationHQ.logo] || []}
              type={"Image"}
              removeImage={() => {}}
            />
            <div className="w-full h-24 mt-4">
              <Upload
                name="avatar"
                text="Click to upload image "
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  uploadAvatar(e);
                }}
              />
            </div>
          </div>
        </>
      ) : null}
      {step === 2 ? (
        <div className="grid grid-cols-1 w-full gap-x-2 content-center">
          {FormData.slice(-4).map((dt, i) => (
            <FormInput
              id={dt.id}
              name={dt.name}
              type={dt.type}
              styles={dt.styles}
              labelStyles={dt.labelStyles}
              onChange={dt.onChange}
              value={dt.value}
              onBlur={dt.onBlur}
              disabled={dt.disabled}
              error={dt.error as any}
              touched={dt.touched as any}
            />
          ))}
        </div>
      ) : null}
      {step === 3 ? (
        <div className="grid grid-cols-1 w-full gap-x-2 content-center">
          <SelectInput
            id="gender"
            data={["Male", "Female"]}
            labelStyles={labelStyles}
            name="Select gender"
            onChange={(e) =>
              Formik.setFieldValue("gender", e.target.value?.toLowerCase())
            }
            value={Formik.values.gender}
          />

          <PasswordInput
            width="w-full"
            id="password"
            name={"Password"}
            type={"text"}
            styles={` ${
              Formik.errors.password && Formik.touched.password
                ? "border-red-500"
                : "border-gray-300"
            }`}
            labelStyles={labelStyles}
            onChange={(e) => {
              Formik.setFieldValue("password", e.target.value);
              Formik.setFieldValue("confirmPassword", e.target.value);
            }}
            value={Formik.values.password}
            onBlur={Formik.handleBlur}
            disabled={addNewResult.isLoading}
            // error={Formik.errors.password}
            // touched={Formik.touched.password}
          />
          <Button
            text="Generate password"
            disabled={addNewResult.isLoading}
            // showModal={loginResult.isLoading}
            className="h-[41px] mt-6 font-bold bg-white border border-[#002E66] rounded-[38px] w-full hover: text-[#002E66]"
            type="button"
            onClick={() => {
              Formik.setFieldValue("password", generatePassword());
            }}
          />
        </div>
      ) : null}

      <div className="w-full">
        {step > 0 ? (
          <Button
            text="Back"
            // disabled={loginResult.isLoading}
            // showModal={loginResult.isLoading}
            className="h-[41px] mt-6 font-bold bg-white border border-[#002E66] rounded-[38px] w-full hover: text-[#002E66]"
            type="button"
            onClick={() => setStep((prev) => prev - 1)}
          />
        ) : null}

        <Button
          text={step < 3 ? "Next" : "Create HQ"}
          disabled={addNewResult?.isLoading}
          showModal={addNewResult?.isLoading}
          className="h-[41px] mt-6 font-bold text-white rounded-[38px] w-full hover: bg-[#002E66]"
          type="submit"
        />
      </div>
    </form>
  );
};

export default AddNewHQ;
