import React, { Fragment, useState } from "react";
import { cardBtnType, FormType } from "src/helpers/alias";
import User from "src/assets/img/User.svg";
import Attendant from "src/assets/img/Attendanticon.svg";
import admin from "src/assets/img/AdminProfile.svg";
import Rating from "src/assets/img/Ratings.svg";
import WalletSvg from "src/assets/img/walletbtn.svg";
import MainProduct from "src/assets/img/MainProduct.svg";
import OtherProduct from "src/assets/img/OtherProduct.svg";
import { CardButton } from "src/components/Card";
import { useMemo } from "react";
import ProfileCard from "src/components/ProfileCard";
import { LoaderContainer } from "src/components/LoaderContainer";
import {
  useChangeStatusMutation,
  useFetchBranchQuery,
} from "src/api/manageBranchAPISlice";
import useCustomLocation from "src/hooks/useCustomLocation";

import AttendantProfile from "./Manage-branch/AttendantProfile";
import BranchReview from "./Manage-branch/BranchReview";
import {
  CurrencyFormatter,
  handleDateFormat,
  handleFormatDate,
  handleNotification,
  splitByUpperCase,
  SuccessNotification,
} from "src/helpers/helperFunction";
import { FlagModal, FormModal, Modal } from "src/components/ModalComp";
import ProfileEditCard from "src/components/EditProfileCard";
import AddAmenityForm from "src/components/AddNewAmenities";
import { Button } from "src/components/Button";
import UserWallet from "./User/UserWallet";
import { useUpdateHqBranchDetailsMutation } from "src/hq-admin/hq-api/manageHqApiSlice";
import {
  useUpdateBranchDetailsMutation,
  useUpdateStationUserMutation,
  useUpdateUserMutation,
} from "src/api/manageUserApi";
import {
  useLockBranchWalletMutation,
  useLockHqWalletMutation,
} from "src/api/walletAPISlice";
import { Box, Tab, Tabs } from "@mui/material";
import { ProgressBar } from "react-toastify/dist/components";
import AddRolePermission, {
  checkPermissions,
} from "src/components/PermissionRestrictorComp";
import { PERMISSION } from "src/helpers/Constant";
import { useAuth } from "src/hooks/useAuth";

const BranchData: cardBtnType[] = [
  {
    id: 1,
    icon: User,
    name: "Branch Profile",
  },
  {
    id: 5,
    icon: admin,
    name: "Branch Manager",
  },
  {
    id: 3,
    icon: Attendant,
    name: "Attendant Profile",
  },
  {
    id: 6,
    icon: WalletSvg,
    name: "Wallet",
  },
  {
    id: 7,
    icon: MainProduct,
    name: "Branch Products",
  },
  {
    id: 8,
    icon: OtherProduct,
    name: "Amenities",
  },
  {
    id: 4,
    icon: Rating,
    name: "Ratings and Reviews",
  },
];

export default function SinglePage() {
  const [tabName, setTabName] = useState<string>("branch profile");
  const { routePath } = useCustomLocation();
  const auth = useAuth();
  const [updateStationUserDetails, updateStationUserDetailsResult] =
    useUpdateStationUserMutation();
  const [status, setStatus] = useState<string>("available");
  const [showModal, setShowModal] = useState<boolean>(false);

  const branchResult: any = useFetchBranchQuery({
    id: routePath?.id,
    params: { populate: ["manager"] },
  });

  const [changeStatus, changeStatusResult] = useChangeStatusMutation();
  const handleModal = () => setShowModal(() => !showModal);

  const handleStatus = () => {
    setStatus("unavailable");
  };

  const handledAPIResponse = useMemo(() => {
    const station = branchResult?.currentData?.station;

    return {
      profileData: {
        name: station?.name,

        phoneNumber: station?.phoneNumber,
        walletNumber: station?.wallets?.walletNumber,
        lga: station?.location?.lga,
        address: station?.location?.address,
        latitude: station?.location?.latitude,
        longitude: station?.location?.longitude,
        state: station?.location?.state,
        hqName: station?.stationHQ?.name,
        pumpCount: station?.config?.pumpCount,
        systemCode: station?.systemCode,

        status: station?.status,
        openingTime: station?.config?.openTime?.from,
        closingTime: station?.config?.openTime?.to,
      },
      id: station?._id,
      config: station?.config,
      logo: station?.coverImage?.url,
      pumpAttendants: station?.pumpAttendants,
      manager: station?.manager,
      stationtanks: station?.stationtanks,
      rating: station?.ratings,
      account: station?.wallets?.availableBalance,
      walletInfo: {
        walletName: station?.wallets?.walletName,
        locked: station?.wallets?.locked,
        walletNumber: station?.wallets?.walletNumber,
        bankName: station?.wallets?.bankName,
      },
    };
  }, [branchResult]);
  console.log(branchResult);
  const handleBranchInfo = (data: any) => {
    console.log(data);
    return {
      name: data.name,
      phoneNumber: data?.phoneNumber,
    };
  };

  const handleSuspendModal = async () => {
    try {
      // const response = await changeStatus({
      //   status:
      //     handledAPIResponse?.profileData.status === "available"
      //       ? "suspend"
      //       : "end",
      //   id: routePath.id,
      // }).unwrap();
      // SuccessNotification(response?.data?.message);
    } catch (error: any) {
      setShowModal(() => false);
      handleNotification(error);
    }
    setShowModal(() => false);
  };

  const tab_name = tabName.trim().toLowerCase();

  const [updateBranchDetails, updateBranchDetailsResult] =
    useUpdateBranchDetailsMutation();

  async function updateBranch(values: FormType) {
    try {
      const response = await updateBranchDetails({
        id: handledAPIResponse.id,
        ...values,
      }).unwrap();
      if (response) {
        SuccessNotification(response?.data?.message);
        setShowModal(() => false);
      }
    } catch (error: any) {
      handleNotification(error);
    }
  }

  async function updateAdminProfile(values: FormType) {
    try {
      const response = await updateStationUserDetails({
        id: handledAPIResponse?.manager?._id,
        ...values,
      }).unwrap();
      console.log(response);
      if (response?.status === "success") {
        SuccessNotification("User Updated");
        setShowModal(() => false);
      }
    } catch (error: any) {
      handleNotification(error);
    }
  }
  const handleManagerInfo = (data: any) => {
    return {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      accountStatus: data?.accountStatus.status,
      phoneNumber: data?.phoneNumber,
      createdAt: handleFormatDate(data?.createdAt),
    };
  };

  const handleManagerEdit = (data: any) => {
    return {
      firstName: data?.firstName,
      lastName: data?.lastName,
      phoneNumber: data?.phoneNumber,
    };
  };

  return (
    <section>
      {/* <LoaderContainer /> */}
      <article className="w-full px-2 pb-10">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4  py-3 ">
          {/* {slicedPath[1] === "branch" ? ( */}
          <>
            {BranchData.map((dt) => (
              <Fragment>
                <CardButton
                  name={dt.name}
                  icon={dt.icon}
                  height={"70px"}
                  activeBtn={tabName}
                  onClick={() => setTabName(dt.name)}
                  // showCard={showCard}
                />
              </Fragment>
            ))}
          </>
        </div>

        <LoaderContainer data={branchResult}>
          {tab_name === "branch profile" ? (
            <ProfileCard
              data={handledAPIResponse.profileData || {}}
              showImage={false}
              imageURL={handledAPIResponse?.logo || ""}
              showBanner={true}
              onClick={() => setTabName("edit branch")}
              buttonText={"Edit Branch"}
              showButton={
                checkPermissions(auth?.user?.role || "", [
                  PERMISSION.SYSTEM_ADMIN,
                ]) && true
              }
              status={status}
              fn={handleStatus}
              flag={true}
              makeRequest={handleSuspendModal}
              handleModal={handleModal}
              apiResult={changeStatusResult}
              showModal={showModal} //"status": "suspended"
              suspend={
                handledAPIResponse?.profileData?.status === "pending"
                  ? "Activate"
                  : handledAPIResponse?.profileData?.status === "available"
                  ? "Suspend"
                  : "Unsuspend"
              }
            />
          ) : null}
          {tab_name === "branch manager" ? (
            <ProfileCard
              data={handleManagerInfo(handledAPIResponse?.manager) || {}}
              showImage={false}
              imageURL={handledAPIResponse?.logo || ""}
              showBanner={true}
              onClick={() => setTabName("edit manager")}
              buttonText={"Edit Manager"}
              showButton={
                checkPermissions(auth?.user?.role || "", [
                  PERMISSION.SYSTEM_ADMIN,
                ]) && true
              }
              status={status}
              fn={handleStatus}
              flag={true}
              makeRequest={handleSuspendModal}
              handleModal={handleModal}
              apiResult={changeStatusResult}
              showModal={showModal} //"status": "suspended"
              suspend={
                handledAPIResponse?.profileData?.status === "pending"
                  ? "Activate"
                  : handledAPIResponse?.profileData?.status === "available"
                  ? "Suspend"
                  : "Unsuspend"
              }
            />
          ) : null}

          {tab_name === "edit branch" ? (
            <ProfileEditCard
              data={handleBranchInfo(handledAPIResponse.profileData) || {}}
              showImage={false}
              header="Edit HQ Information"
              showSecondButton={true}
              showButton={true}
              status={status}
              secondOnClick={() => setTabName("branch profile")}
              secondButtonText={"Cancel"}
              fn={handleStatus}
              flag={true}
              makeRequest={updateBranch}
              handleModal={handleModal}
              apiResult={updateBranchDetailsResult}
              showModal={showModal} //"status": "suspended"
              suspend={
                handledAPIResponse?.profileData?.status === "pending"
                  ? "Activate"
                  : handledAPIResponse?.profileData?.status === "available"
                  ? "Suspend"
                  : "Unsuspend"
              }
            />
          ) : null}

          {tab_name === "edit manager" ? (
            <ProfileEditCard
              data={handleManagerEdit(handledAPIResponse.manager) || {}}
              showImage={false}
              header="Edit HQ Information"
              showSecondButton={true}
              showButton={true}
              status={status}
              secondOnClick={() => setTabName("branch profile")}
              secondButtonText={"Cancel"}
              fn={handleStatus}
              flag={true}
              makeRequest={updateAdminProfile}
              handleModal={handleModal}
              apiResult={updateBranchDetailsResult}
              showModal={updateStationUserDetailsResult.isLoading} //"status": "suspended"
              suspend={
                handledAPIResponse?.profileData?.status === "pending"
                  ? "Activate"
                  : handledAPIResponse?.profileData?.status === "available"
                  ? "Suspend"
                  : "Unsuspend"
              }
            />
          ) : null}

          {/* {tab_name === "view wallet" ? <ViewWallet /> : null} */}
          {tab_name === "attendant profile" ? (
            <AttendantProfile
              attendantData={handledAPIResponse?.pumpAttendants}
              stationID={handledAPIResponse?.id}
            />
          ) : null}
          {tab_name === "ratings and reviews" ? (
            <BranchReview
              branchId={handledAPIResponse?.id}
              ratings={handledAPIResponse.rating}
            />
          ) : null}
          {tab_name === "wallet" ? (
            <BranchAccountBalance
              account={handledAPIResponse.account}
              info={handledAPIResponse}
              id={handledAPIResponse?.id}
            />
          ) : null}
          {tab_name === "branch products" ? (
            <ProductCard data={handledAPIResponse?.stationtanks} />
          ) : null}
          {tab_name === "amenities" ? (
            <AmenitiesCard data={handledAPIResponse.config?.amenities} />
          ) : null}
        </LoaderContainer>
      </article>
      {/* {showModalUpdate ? (
        <FormModal
          name="Update branch details"
          onClick={() => setShowModalUpdate((prevState) => !prevState)}
        >
          <AddNewBranch
            makeApiRequest={(values) => updateBranch(values)}
            apiResult={updateBranchDetailsResult}
            initalValue={station}
            showSelectHQ={false}
            getFilteredValue={() => {}}
          />
        </FormModal>
      ) : null} */}
      {showModal && (
        <Modal>
          <FlagModal
            info={`Are you sure you want to ${
              handledAPIResponse?.profileData?.status === "confirmed"
                ? `Suspend ${handledAPIResponse?.profileData?.name}`
                : handledAPIResponse?.profileData?.status === "deactivated"
                ? `activate ${handledAPIResponse?.profileData?.name}`
                : `Unsuspend ${handledAPIResponse?.profileData?.name}`
            }?`}
            onClose={handleModal}
            onConfirmation={handleSuspendModal}
            showModal={branchResult?.isLoading}
          />
        </Modal>
      )}
    </section>
  );
}

export const BranchAccountBalance = (props: {
  account: string;
  id: string;
  info: { [index: string]: string | any };
}) => {
  const auth = useAuth();
  const [activeTab, setActiveTab] = useState<string>("balance");
  const [walletData, setWalletData] = useState<any>(props.info.walletInfo);
  const [showLockModal, setShowLockModal] = useState<boolean>(false);
  const [isLocking, setIsLocking] = useState<boolean>(true);

  const toggleTab = (tab: string) => setActiveTab(tab);

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletData({ ...walletData, [e.target.name]: e.target.value });
  };

  const [lockBranchWallet, lockBranchWalletDetails] =
    useLockBranchWalletMutation();

  const handleLockWallet = async () => {
    try {
      const response = await lockBranchWallet({
        id: props?.id,
        locked: true,
        canWithdraw: true,
      }).unwrap();
      SuccessNotification("Wallet Locked");
      setShowLockModal(false);
    } catch (error: any) {
      handleNotification(error);
    }
  };

  const handleUnlockWallet = async () => {
    try {
      const response = await lockBranchWallet({
        id: props?.id,
        locked: false,
        canWithdraw: false,
      }).unwrap();
      if (response.status === "success") {
        SuccessNotification("Wallet Unlocked");
      }

      setShowLockModal(false);
      setShowLockModal(false);
    } catch (error: any) {
      handleNotification(error);
    }
  };

  const btnClass =
    "h-full font-bold text-white rounded-lg px-6 py-2 bg-[#002E66] hover:bg-[#004b99] transition duration-300";

  const handleChange = (event: any) => {
    const t: string = event.target.textContent;
    t.toLowerCase().includes("balance")
      ? setActiveTab("balance")
      : setActiveTab("lock");
  };

  return (
    <div className="pb-10">
      <div className="p-6 basis-[60%] rounded-[10px] bg-white mt-1 shadow-lg">
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={activeTab}
            onClick={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              sx={{
                fontSize: 14,
              }}
              key={"balance"}
              value={"balance"}
              label={"Balance"}
            />
            {checkPermissions(auth?.user?.role || "", [
              PERMISSION.SYSTEM_ADMIN,
            ]) && (
              <Tab
                sx={{
                  fontSize: 14,
                }}
                key={"lock"}
                value={"lock"}
                label={"Lock Wallet"}
              />
            )}
          </Tabs>
        </Box>

        {activeTab === "balance" && (
          <div className="grid gap-1">
            <AddRolePermission
              rolePermission={[PERMISSION.SYSTEM_ADMIN]}
              empty={true}
            >
              <div className="text-center text-[#002E66]">
                <h3 className="text-[14px]">Available Balance</h3>
                <h2 className="text-[24px] font-bold">
                  {CurrencyFormatter(Number(props.account))}
                </h2>
              </div>
            </AddRolePermission>

            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {Object.keys(props.info.walletInfo).map((key, i) => (
                <div
                  key={i + 1}
                  className="bg-gray-100 p-4 pt-2 pb-1 rounded-lg"
                >
                  <h2 className="text-black capitalize">
                    {splitByUpperCase(key)}
                  </h2>
                  <h2 className="text-[#002E66]">
                    {key === "locked"
                      ? props.info.walletInfo[key]
                        ? "Locked"
                        : "Not Locked"
                      : props.info.walletInfo[key]}
                  </h2>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === "lock" && (
          <div className="flex w-full flex-col gap-4 items-center justify-between">
            {!props.info.walletInfo.locked && (
              <div className="flex w-full items-center justify-between">
                <p className="my-4">Wallet is unlocked, lock?</p>
                <Button
                  type="button"
                  text="Lock Wallet"
                  className={btnClass}
                  showIcon={false}
                  onClick={() => {
                    setIsLocking(true);
                    setShowLockModal(true);
                  }}
                />
              </div>
            )}

            {checkPermissions(auth?.user?.role || "", [
              PERMISSION.SYSTEM_ADMIN,
            ]) &&
              props.info.walletInfo.locked && (
                <div className="flex w-full items-center justify-between">
                  <p className="my-4">Wallet is locked, unlock?</p>
                  <Button
                    type="button"
                    text="Unlock Wallet"
                    className={btnClass}
                    showIcon={false}
                    onClick={() => {
                      setIsLocking(false);
                      setShowLockModal(true);
                    }}
                  />{" "}
                </div>
              )}
          </div>
        )}
      </div>

      <UserWallet id={props.info.profileData.id} />

      {showLockModal && (
        <FormModal
          name={isLocking ? "Lock Wallet" : "Unlock Wallet"}
          onClick={() => setShowLockModal(false)}
        >
          <div className="p-4">
            {/* <h2 className="text-lg font-bold">
              {isLocking ? "Lock Wallet" : "Unlock Wallet"}
            </h2> */}
            <p className="my-4">
              Are you sure you want to {isLocking ? "lock" : "unlock"} the
              wallet?
            </p>
            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                text="Cancel"
                className="px-4 py-2 bg-gray-500 rounded"
                showIcon={false}
                onClick={() => setShowLockModal(false)}
              />
              <Button
                type="button"
                showModal={lockBranchWalletDetails.isLoading}
                text="Confirm"
                className="px-4 py-2 bg-[#002E66] rounded text-white"
                showIcon={false}
                onClick={isLocking ? handleLockWallet : handleUnlockWallet}
              />
            </div>
          </div>
        </FormModal>
      )}
    </div>
  );
};

function ProductCard({ data }: { data: any }) {
  return (
    <div className="w-full h-fit bg-white shadow-lg rounded-lg text-[14px] py-6">
      {data?.length === 0 ? (
        <div className="py-24 w-full justify-center items-center">
          <h1>No Products(s) Available</h1>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10 py-4 md:gap-x-2 text-start px-4 lg:px-16">
            {data?.map((dt: any, i: number) => (
              <Fragment key={i + 1}>
                <div>
                  <h2 className="text-black capitalize">
                    {splitByUpperCase(dt?.product)}
                  </h2>
                  <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg capitalize"></span>
                  <h2 className="text-[#002E66] capitalize">
                    {CurrencyFormatter(dt?.price ?? 0)}
                  </h2>

                  <h2 className="text-[#002E66] capitalize">
                    Available:
                    {!dt?.isAvailable ? " NO" : " YES"}
                  </h2>
                  {/* {show ? (
								<CustomizedSwitches
									defaultChecked={dt?.isAvailable}
									off="off"
									on="on"
								/>
							) : null} */}
                </div>
              </Fragment>
            ))}
          </div>
          <div className="py-6 px-10">
            <h2 className="font-bold text-lg text-left">
              Product Level Readings
            </h2>
            <div className="w-full h-full grid grid-cols-2 lg:grid-cols-4 ">
              {data?.map((item: any, i: number) => (
                <div className="p-4 flex flex-col items-center" key={i + 1}>
                  <h3 className="capitalize pb-3">
                    {item?.product} {item?.fuelLevelPercentage || 0}%
                  </h3>
                  <div
                    className={`h-3 py-1 w-full rounded-lg  flex items-center overflow-hidden bg-gray`}
                  >
                    <span
                      className={`block h-3 ${
                        Math.ceil(item?.fuelLevelPercentage) >= 15
                          ? "bg-[#002E66]"
                          : "bg-red-500"
                      } `}
                      style={{
                        width: `${Math.ceil(item?.fuelLevelPercentage)}%`,
                      }}
                    ></span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function ProductCard2(props: any) {
  return (
    <div className="w-full h-fit bg-white shadow-lg rounded-lg text-[14px] py-6">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-10 py-4 md:gap-x-2 text-start px-4 lg:px-16">
        {props?.data?.map((dt: any, i: any) => (
          <Fragment key={i}>
            <div>
              <h2 className="text-black capitalize">
                {splitByUpperCase(dt?.name ?? null)}
              </h2>
              <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg capitalize"></span>
              <h2 className="text-[#002E66] capitalize">
                {CurrencyFormatter(dt?.price ?? null)}
              </h2>

              <h2 className="text-[#002E66] capitalize">
                Available:
                {!dt?.isAvailable ? " NO" : " YES"}
              </h2>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function AmenitiesCard(props: any) {
  const auth = useAuth();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  function closeAddAmenitiesModal(): void {
    setShowAddModal((prev) => !prev);
  }
  return (
    <div className="w-full h-fit bg-white shadow-lg rounded-lg text-[14px] py-6  px-4 lg:px-16 py-4">
      {checkPermissions(auth?.user?.role || "", [PERMISSION.SYSTEM_ADMIN]) && (
        <div className=" h-11  flex w-full mb-5 justify-start">
          <Button
            text="Create Amenities"
            type="button"
            showIcon={true}
            onClick={() => setShowAddModal(true)}
          />
        </div>
      )}
      {showAddModal ? (
        <FormModal
          name="Create Amenities"
          onClick={() => setShowAddModal((prevState) => !prevState)}
        >
          <AddAmenityForm close={closeAddAmenitiesModal} />{" "}
        </FormModal>
      ) : null}
      {props?.data?.length === 0 ? (
        <div className="py-24 w-full justify-center items-center">
          <h1>No Amenities(s) Available</h1>
        </div>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-10  md:gap-x-2 text-start">
          {Object.keys(props?.data)?.map((dt, i) => (
            <Fragment key={i + 1}>
              {i > 1 ? (
                <div>
                  <h2 className="text-black capitalize">
                    {splitByUpperCase(props?.data[dt]?.name ?? null)}
                  </h2>
                  <span className="block bg-[#737587] h-0.5 w-20 my-1.5 rounded-lg capitalize"></span>
                  {/* <h2 className="text-[#002E66] capitalize">
                  {CurrencyFormatter(props?.data[dt]?.price ?? null)}
                </h2>

                <h2 className="text-[#002E66] capitalize">
                  Available:
                  {!props?.data[dt]?.isAvailable ? " NO" : " YES"}
                </h2> */}
                </div>
              ) : null}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}
