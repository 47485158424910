import React, { useCallback, useEffect, useMemo } from "react";
import "./App.css";
import { Suspense, lazy } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTE, SUB_DOMAIN } from "./helpers/Constant";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  logOut,
  setCredentials,
  showDropDown,
} from "./features/auth/authSlice";
import { useAppDispatch } from "./hooks/reduxhooks";

import { AdminRoute } from "./route";
import Login from "./screens/authentication/Login";
import { decryptData } from "./helpers/encryptData";
import { HQ_Route } from "./hq-admin/hq-route";
import ForgotPassword from "./screens/authentication/ForgotPassword";
import ResetPassword from "./screens/authentication/ResetPassword";
import { useInactivityTimeout } from "./helpers/helperFunction";
import { useDispatch } from "react-redux";
import VerifyEmail from "./screens/authentication/OtpVerificationPage";
// import { LandingPage } from "./screens";
// import AboutUs from "./screens/landing-page/AboutUs";
// import ContactUs from "./screens/landing-page/ContactUs";

const Entry = lazy(() => import("./screens/protected"));

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const domainHost = useMemo(() => {
    return window.location.host.split(".")[0];
  }, []);

  const handleTimeout = useCallback(() => {
    if (!location.pathname.includes("login")) return;
    alert("You have been logged out due to inactivity.");
    dispatch(logOut());
    navigate("/login");
  }, [dispatch]);

  useInactivityTimeout(10 * 60 * 1000, handleTimeout);

  const persisteUserInfo = useMemo(() => {
    let userInfo = decryptData("fuleap-user-info") || {};

    if (Object.keys(userInfo).length === 0) {
      return;
    }

    return JSON.parse(userInfo);
  }, []);

  useEffect(() => {
    if (!persisteUserInfo) return;
    dispatch(setCredentials(persisteUserInfo));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dispatch]);

  const handleExpandClick = () => {
    // setExpanded(!expanded);
    dispatch(showDropDown(false));
  };
  return (
    <div className="App" onClick={handleExpandClick}>
      <Suspense fallback="loading...">
        <Routes>
          {/* <Route path={APP_ROUTE.LANDING_PAGE} element={<LandingPage />} /> */}
          {/* <Route path={APP_ROUTE.ABOUT_US} element={<AboutUs />} />
					<Route path={APP_ROUTE.CONTACT_US} element={<ContactUs />} /> */}
          <Route path={APP_ROUTE.LOGIN} element={<Login host={domainHost} />} />
          <Route
            path={APP_ROUTE.FORGOT_PASSWORD}
            element={<ForgotPassword host={domainHost} />}
          />
          <Route path={APP_ROUTE.OTP_VERIFICATION} element={<VerifyEmail />} />
          <Route path={APP_ROUTE.All} element={<Entry />} />

          <Route
            path={APP_ROUTE.RESET_PASSWORD}
            element={<ResetPassword host={domainHost} />}
          />
          <Route path={APP_ROUTE.DASHBOARD} element={<Entry />}>
            {AdminRoute(domainHost)}
            {/* {domainHost === SUB_DOMAIN.SYSTEM_ADMIN
							? AdminRoute(domainHost)
							: HQ_Route(domainHost)} */}
            {/* {console.log(domainHost)}
						{domainHost === SUB_DOMAIN.SYSTEM_ADMIN
							? AdminRoute(domainHost)
							: null}
						{domainHost === SUB_DOMAIN.HQ ? HQ_Route(domainHost) : null} */}
            {/* {domainHost !== SUB_DOMAIN.HQ ||
							domainHost !== SUB_DOMAIN.SYSTEM_ADMIN? <Navigate} */}
          </Route>
        </Routes>
      </Suspense>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        closeOnClick
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}

export default App;
